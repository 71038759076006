import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const Management = () => {
    return (
        <Layout>
            <Seo title="Management" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Management</h1>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxl-4 g-3 mb-3">
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../../../images/management/muditpaliwal.png"
                                alt="Mudit Paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Mudit Paliwal</h5>
                                <p className="card-text">Founder & CEO</p>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../../../images/management/andrewbenjamin.jpg"
                                alt="Andrew Benjamin"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Andrew Benjamin</h5>
                                <p className="card-text">Executive VP Risk</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../../../images/management/carolinehuot.jpg"
                                alt="Caroline Huot"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Caroline Huot</h5>
                                <p className="card-text">Managaing Director</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../../../images/management/das.jpg"
                                alt="Sumanta Das"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Capt. Sumanta Das</h5>
                                <p className="card-text">Executive VP Operations & DPA</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../../../images/employees/mathews.jpg"
                                alt="Robin Mathew"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Capt. Robin Mathews</h5>
                                <p className="card-text">Alternate DPA</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../../../images/employees/sood.jpg"
                                alt="Khyati Chopra"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Khyati Chopra</h5>
                                <p className="card-text">Fleet Personnel</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../../../images/management/pedersen.jpg"
                                alt="Khyati Chopra"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Svein Eloff Pedersen</h5>
                                <p className="card-text">JMD Commercial</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../../../images/management/arakal.jpg"
                                alt="Khyati Chopra"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Thomas Chacko Arakal</h5>
                                <p className="card-text">JMD Technical</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col">
                        <div className="card-body h-100 bg-dark ">
                            <blockquote className="blockquote text-light mb-0">
                                <p>"Leading through action, experience & dedication."</p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Management
